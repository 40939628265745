@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300&family=Bungee+Outline&display=swap');

body {
  background-color: #102e4e;
}

.App {
  text-align: center;
}

.App-logo-symbol-mobile {
  height: auto;
  width: 100px;
  margin: 30px;
}

.App-logo-symbol-desktop {
  height: auto;
  width: 180px;
  margin: 30px;
}

.App-logo-wordmark-mobile {
  height: auto;
  width: 250px;
}

.App-logo-wordmark-desktop {
  height: auto;
  width: 350px;
}

.Main-content {
  font-family: 'Barlow Condensed', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: -24px;
}

.Background-text{
  font-family: 'Bungee Outline', sans-serif;
  opacity: 75%;
  color: #3f72af;
}

.Social-icon{
  margin: 1px;
}

footer{
  color: white; 
  height: 24px;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 14px;
}

.navbar-custom-background {
  
}

@keyframes fadeIn {
  from {
     opacity: 0;
  }
  to {
     opacity: 1;
  }
}

.fade-in {
 opacity: 0;
 animation: fadeIn ease-in 1;
 animation-fill-mode: forwards;
 animation-duration: 1s;
}